<template>
  <div class="view pa24">
    <div class="cA1 fs9 mb20 fwbold">顶部背景色配置</div>
    <div class="d-flex align-items-center pl30 mb20">
      <p class="cA3">选择顶部背景色</p>
      <div class="w100 h40 bced br5 ml20" :style="{background:color}">
      </div>
      <div class="d-flex flex-column justify-content-between ml20">
        <p>
          注：默认白色背景色，黑色大于rgb(30,30,30).白色小于rgb（235,235,235）
        </p>
        <p class="cA1">
          除"媒体"页面外，“名片”、“商城”、“动态”、“官网”和“增值服务”页面的顶部背景色将根据设置的颜色显示
        </p>
      </div>
    </div>
    <div class="pl30">
      <sketch-picker v-model="color" @input="colorValueChange"></sketch-picker>
    </div>
    <div class="pl30 mt20"> 
      <el-button size="small"
                 type="primary" 
                 @click="saveColorValue">
            保存
      </el-button>
    </div>
    
  </div>
</template>

<script>
import {
  addColorValue,
  queryColorValue,
  getCompany
} from "@/api/miniProgram"
import { Sketch } from 'vue-color'

export default {
  components: {'sketch-picker':Sketch},
  data() {
    return {
      color: '#ffffff',
      companyInfo:''
    };
  },
  async created(){
    let result = await getCompany()
    this.companyInfo = result.data
    this.queryColorValue()
  },
  methods:{
    // 颜色值改变事件处理
    colorValueChange (val) {
      // 取颜色对象的十六进制值
      this.color = val.hex
    },
    queryColorValue(){
      let data = {
        companyId:this.companyInfo.companyId
      }
      queryColorValue(data)
      .then(res=>{
        //-console.log(res)
        this.color = res.data.colorValue
      })
    },
    saveColorValue(){
      let data = {
        colorValue:this.color,
        companyId:this.companyInfo.companyId
      }
      addColorValue(data)
      .then(res=>{
        //-console.log(res)
        if(res.code == 200){
          this.$message.success('保存成功')
        }
      })
      .catch(err=>{
        this.$message.error('保存失败')
      })
    }
  },
  
  
};
</script>

<style lang="scss" scoped>

</style>
