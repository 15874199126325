<template>
  <div class="appointmentList-view flex-a-b-c" @mouseup="moveEnd" v-loading="getCardLoading"
    @click="setShowscale(false)">
    <div class="modelList" v-if="setViewType != 'nav'">
      <p class="fs9 fwbold mb20 pl10 mt20">选择名片模板</p>
      <div v-for="(row, index) in modelList" :key="index" class="modelImg" :class="{ 'on': modelIndex == index }"
        @click="checkedModel(row, index)">
        <div class="onImg"><i class="el-icon-success"></i> </div>
        <el-image class="mImg" :src="row.photo"></el-image>
        <div class="version">
          <span class="bzb" v-if="row.version == 0">{{ versionData[row.version] }}</span>
          <span class="gjb" v-if="row.version == 1">{{ versionData[row.version] }}</span>
          <span class="szb" v-if="row.version == 2">{{ versionData[row.version] }}</span>
        </div>
      </div>
    </div>
    <div class="flex-j-c pt60 pb30" :class="{ 'app-view': setViewType != 'nav', 'app-view2': setViewType == 'nav' }">
      <el-button type="primary" class="submitCard" @click="submitCardInfo"
        v-if="setViewType == 'card'">保存个人名片</el-button>
      <el-button type="primary" class="submitCard" @click="submitCompanyCardInfo"
        v-if="setViewType == 'comanyCard'">保存企业名片</el-button>
      <div>
        <el-image class="phoneHeader" :src="phoneHeader"></el-image>
        <div class="phoneClass borderboxd-flex flex-column">
          <div style="height:730px;width:375px;overflow: hidden;position: relative;">
            <img src="../../../assets/images/content1.png">
            <div class="web-view">
              <div class="setCardBottom" v-if="!isDisabledSetCard"></div>
              <div class="setCard" v-if="!isDisabledSetCard" :style="{ backgroundImage: 'url(' + backImg + ')' }"
                @click.stop="setShowscale(true)">
                <div class="cardBackimg" :style="{ backgroundImage: 'url(' + backImg + ')' }"></div>
                <div v-for="(item, index) in setCardStyle" :key="index" class="cardInfo"
                  :class="{ 'on': setCardIndex == index, 'hide': !item.isShow, 'rightHalfBackgroundImg': modelType == 'rightHalfBackgroundImg' && item.key === 'logo', 'leftHalfBackgroundImg': modelType == 'leftHalfBackgroundImg' && item.key === 'logo' }"
                  :style="{ borderRadius: item.borderRadius }">
                  <div v-if="item.key === 'logo'" @mousedown="moveStart($event, index)" class="setLogo logoImg"
                    :style="{ borderRadius: item.borderRadius }">
                    <el-image :src="item.url" :style="{ 'height': '100%', 'width': '100%' }"></el-image>
                  </div>
                  <div v-else class="setText d-flex" @mousedown="moveStart($event, index)"
                    :style="{ 'justify-content': item.textAlign, 'align-items': item.alignItems }">

                    <div class="flex-c-c"
                      :style="{ 'font-size': item.size + 'px', 'color': item.color, 'font-family': item.fontFamily, 'font-style': item.fontStyle, 'font-weight': item.fontWeight }">
                      <img :src="item.iconFont" :style="{ width: item.size + 5 + 'px', height: item.size + 5 + 'px' }"
                        class="mr5 infoIconFont" alt="" v-if="item.iconFont && item.textAlign != 'flex-end'">
                        {{ item.content }}
                      <img :src="item.iconFont" :style="{ width: item.size + 5 + 'px', height: item.size + 5 + 'px' }"
                        class="ml5 infoIconFont" alt="" v-if="item.iconFont && item.textAlign == 'flex-end'">
                    </div>

                  </div>
                  <div class="ltd" v-if="isShowscale" @mousedown="moveSizeStart($event, 'leftTop')"></div>
                  <div class="lcd" v-if="isShowscale" @mousedown="moveSizeStart($event, 'leftCenter')"></div>
                  <div class="lbd" v-if="isShowscale" @mousedown="moveSizeStart($event, 'leftBottom')"></div>
                  <div class="rtd" v-if="isShowscale" @mousedown="moveSizeStart($event, 'rightTop')"></div>
                  <div class="rcd" v-if="isShowscale" @mousedown="moveSizeStart($event, 'rightCenter')"></div>
                  <div class="rbd" v-if="isShowscale" @mousedown="moveSizeStart($event, 'rightBottom')"></div>
                  <div class="tcd" v-if="isShowscale" @mousedown="moveSizeStart($event, 'topCenter')"></div>
                  <div class="bcd" v-if="isShowscale" @mousedown="moveSizeStart($event, 'bottomCenter')"></div>
                </div>
                <div class="line lineTop"></div>
                <div class="line lineRight"></div>
                <div class="line lineBottom"></div>
                <div class="line lineLeft"></div>
                <div class="line lineCenterX"></div>
                <div class="line lineCenterY"></div>
              </div>
            </div>
          </div>

          <div class="d-flex flex1 bgfff justify-content-between">
            <div style="width:52px;height:100%" class="flex-c-c flex-column" v-for="(item, index) in tabList"
              :key="index">
              <img :src="item.iconPath" class="w40 h40">
              <p class="fs6 ">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>

    </div>
    <el-tabs v-model="setViewType" class="setView" :before-leave="beforeLeave" @tab-click="switchCardType">
      <el-tab-pane label="导航模板" name="nav">
        <div class="navigation-view mt20 pl10 pr10 mb20">
          <p class="fs9 fwbold mb20 pl10">因微信小程序机制，更改内容后需提交小程序审核，发布后才可生效</p>
          <el-collapse class="mr10 ml10">
            <el-collapse-item :name="index" v-for="(item, index) in tabList" :key="index">
              <template slot="title">
                <el-image style="height:40px;width:40px" :src="item.iconPath"></el-image>{{ item.text }}
              </template>
              <div class="bgf5f6 pt10 pb10 w100p pr10 pl10  borderbox mb20">
                <div class="pl10 flex-a-c mb10">
                  <div class="w100">
                    <p class="">名称</p>
                  </div>
                  <el-input type="text" class="w400" maxlength="4" v-model="item.text" :disabled="index == 0"
                    show-word-limit>
                  </el-input>
                </div>

                <div class="pl10 flex-a-c mb10">
                  <div class="w100">
                    <p class="">图标样式</p>
                  </div>
                  <div class="flex-a-c ">
                    <p class="mr10">未选中状态</p>
                    <el-upload accept="image/jpeg,image/png" class="addTabIcon" :action="uploadUrl"
                      :show-file-list="false"
                      :on-success="dynamicPicSuccess.bind(null, { 'index': index, 'data': item })"
                      :before-upload="beforeUploadImageDynamicPic">
                      <img v-if="item.iconPath" :src="item.iconPath" class="avatar dynamic">
                      <i v-else class="el-icon-plus"></i>
                    </el-upload>
                    <div class="flex-a-c">
                      <p class="mr10">选中状态</p>

                      <el-upload accept="image/jpeg,image/png" class="addTabIcon" :action="uploadUrl"
                        :show-file-list="false"
                        :on-success="dynamicPicSuccessA.bind(null, { 'index': index, 'data': item })"
                        :before-upload="beforeUploadImageDynamicPic">
                        <img v-if="item.selectedIconPath" :src="item.selectedIconPath" class="avatar dynamic">
                        <i v-else class="el-icon-plus"></i>
                      </el-upload>
                      <p>建议上传81px * 81px的图标</p>
                    </div>
                  </div>
                </div>

                <div class="pl10 flex-a-b-c">
                  <div class="flex-a-c">
                    <div class="w100">展示页面</div>
                    <el-select v-model="item.subheading" style="width: 240px" placeholder="请选择" :disabled="index == 0"
                      @change="select_tap($event, index)">
                      <el-option v-for="(v) in optionList" :key="v.text" :disabled="v.disabled" :label="v.text"
                        :value="v.text" />
                    </el-select>
                  </div>
                  <i v-if="index !== 0" class="el-icon-delete fs9 hover_pointer" @click="deleteTab(item)"></i>

                </div>

              </div>
            </el-collapse-item>
          </el-collapse>
          <div class="flex-c-c">
            <el-button-group class="mt10">
              <el-button type="primary" round @click="addTab">添加导航模板</el-button>
              <el-button type="primary" round @click="saveTab">保存</el-button>
            </el-button-group>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="设计个人名片" :disabled="isDisabledSetCard" name="card">
        <div class="pl10 pr10 mb20 pt10">
          <el-alert v-if="unifiedStyle" title="企业已开启名片统一，只有解锁名片版本才能使用自己编辑的名片" type="error">
          </el-alert>
          <p class="fs9 fwbold mb20 pl10 mt10">当前设置的名片样式可能与小程序中的名片样式有偏差，请以小程序为准</p>
          <div>
            <div class="pt10 pb10 pr10 pl10 w100p borderbox">
              <p class="fs9 fwbold">设计名片</p>
              <div class="mb10 cA3" v-if="modelType == 'allBackgroundImg'">建议上传尺寸1080*640px</div>
              <div class="mb10 cA3" v-else>建议上传背景为一半透明，尺寸为1080*640px</div>
              <div class="pl10 mb10 d-flex">
                <div class="w70"></div>
                <el-upload accept="image/jpeg,image/png" list-type="picture-card" class="addCardImg"
                  :show-file-list="false" :action="uploadUrl" :on-success="cardBackSuccess"
                  :before-upload="beforeUploadImageDynamicPic">
                  <!-- <img v-if="backImg" :src="backImg" class="avatar dynamic"> -->
                  <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-delete">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                  <div class="nullImg" slot="default">
                    <i class="el-icon-plus"></i>
                    <p>上传名片背景图</p>
                  </div>
                </el-upload>
              </div>
              <el-divider content-position="left">背景库</el-divider>
              <div class="d-flex flex-wrap-y pl15 pr15">
                <div v-for="(url, index) in uploadBackImgList" class="modelBackImg" :key="'u' + index"
                  @click="checkedBack(url)" :class="{ 'on': backImg == url }">
                  <div class="onImg"><i class="el-icon-success"></i></div>
                  <el-image class="mImg" :src="url"></el-image>
                  <i class="el-icon-delete-solid" @click.stop="delCardBack(index)"></i>
                </div>
                <div v-for="(url, index) in backImgList" class="modelBackImg" :key="index" @click="checkedBack(url)"
                  :class="{ 'on': backImg == url }">
                  <div class="onImg"><i class="el-icon-success"></i></div>
                  <el-image class="mImg" :src="url"></el-image>
                </div>
              </div>
            </div>
            <el-collapse class="mr10 ml10" v-model="setCardIndex" @change="showSetCard" :accordion="true">
              <el-collapse-item :name="index" v-for="(item, index) in setCardStyle" :key="index">
                <template slot="title">
                  <el-switch v-model="item.isShow" class="mr20"
                    v-if="item.key != 'info' && (modelType == 'allBackgroundImg' || item.key != 'logo')">
                  </el-switch>
                  <i class="el-icon-delete mr20 corange" @click="delCardInfo(index)" v-if="item.key == 'info'"></i>{{
    item.title }}
                </template>
                <div v-if="item.key == 'logo'">
                  <div class="d-flex pl10 pt10">
                    <div class="w70">
                      展示Logo
                    </div>
                    <div>
                      <el-upload accept="image/jpeg,image/png" class="addLogo" list-type="picture-card"
                        :action="uploadUrl" :on-success="logoSuccess" :class="item.url ? 'disable' : ''"
                        :file-list="logoFile" :before-upload="beforeUploadImageDynamicPic">
                        <i slot="default" class="el-icon-plus"></i>
                        <div slot="file" slot-scope="{file}">
                          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                          <span class="el-upload-list__item-actions">
                            <span class="el-upload-list__item-delete" @click="delLogoImg(index)">
                              <i class="el-icon-delete"></i>
                            </span>
                          </span>
                        </div>
                      </el-upload>
                      <p>建议上传81px * 81px的图标</p>
                    </div>
                  </div>
                  <div class="d-flex pl10 mt10 styleFun bgf5f6" v-if="modelType == 'allBackgroundImg'">
                    <div class="mr20">
                      使用圆形外观
                    </div>
                    <div>
                      <el-switch v-model="item.borderRadius" inactive-value="10%" active-value="50%">
                      </el-switch>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="pl10 mb10 pt10 flex-a-c">
                    <div class="w50">
                      内容
                    </div>
                    <el-input type="text" :disabled="item.key === 'company' || item.key === 'position'"
                      v-model="item.content" class="w300" show-word-limit>
                    </el-input>
                  </div>
                  <el-divider content-position="left">样式设置</el-divider>
                  <div class="pl10 mb10 flex-a-c">
                    <div class="w50">
                      图标
                    </div>
                    <el-upload accept="image/jpeg,image/png" class="cardInfoIcon" list-type="picture-card"
                      :action="uploadUrl" :on-success="cardInfoIconSuccess.bind(null, { 'index': index, 'data': item })"
                      :class="item.iconFont ? 'disable' : ''" :file-list="item.iconFontFile"
                      :before-upload="beforeUploadImageDynamicPic">
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{file}">
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                        <span class="el-upload-list__item-actions">
                          <span class="el-upload-list__item-delete" @click="delCardInfoIcon(index)">
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                  </div>
                  <div class="pl10 mb10 flex-a-c">
                    <div class="w50">
                      字体
                    </div>
                    <el-select v-model="item.fontFamily" class="w250" placeholder="请选择">
                      <el-option v-for="(item, index) in familyData" :key="index" :label="item.title"
                        :value="item.style">
                      </el-option>
                    </el-select>
                    <el-color-picker class="ml10" v-model="item.color" show-alpha :predefine="textColorData">
                    </el-color-picker>
                  </div>
                  <div class="pl10 flex-a-b-c">
                    <div class="flex-a-c">
                      <div class="w50">字号</div>
                      <el-slider v-model="item.size" class="w300" :max="24" :min="12"></el-slider>
                    </div>
                  </div>
                  <div class="flex-c-c pl10 mt10 styleFun bgf5f6">
                    <div>
                      <div class="pr10">
                        <el-radio-group v-model="item.textAlign">
                          <el-radio-button label="flex-start">左对齐</el-radio-button>
                          <el-radio-button label="center">水平居中</el-radio-button>
                          <el-radio-button label="flex-end">右对齐</el-radio-button>
                        </el-radio-group>
                      </div>
                      <div class="mt10 mb10" style="width:100%; height:1px; background-color: #dddddd;"></div>
                      <div>
                        <el-radio-group v-model="item.alignItems">
                          <el-radio-button label="flex-start">上对齐</el-radio-button>
                          <el-radio-button label="center">垂直居中</el-radio-button>
                          <el-radio-button label="flex-end">下对齐</el-radio-button>
                        </el-radio-group>
                      </div>
                    </div>
                    <div style="width:1px; height:40px; background-color: #dddddd;"></div>
                    <div>
                      <div class="pl10">
                        <el-checkbox-button v-model="item.fontWeight" label="bold" true-label="bold"
                          false-label="">加粗</el-checkbox-button>
                      </div>
                      <div class="mt10 mb10" style="width:100%; height:1px; background-color: #dddddd;"></div>
                      <div class="pl10">
                        <el-checkbox-button v-model="item.fontStyle" label="oblique" true-label="oblique"
                          false-label="">倾斜</el-checkbox-button>
                      </div>
                    </div>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="flex-c-c">
            <el-button type="primary" class="mt10 w400" round @click="showAddCardInfoFun">添加名片信息</el-button>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="设计企业名片" v-if="userInfo.userType && userInfo.userType > 0" :disabled="isDisabledSetCard"
        name="comanyCard">
        <div class="d-flex mt20 pl20">
          <div class="w130">是否开启名片统一<i class="el-icon-warning-outline" title="开启后企业员工的名片会统一成该名片样式。解锁了名片版本，不会被统一"></i>
          </div>
          <el-switch v-model="unifiedStyle" @change="unifiedStyleFun" class="mr20">
          </el-switch>
        </div>
        <div class="pl10 pr10 mb20 pt10">
          <p class="fs9 fwbold mb20 pl10 mt10">当前设置的名片样式可能与小程序中的名片样式有偏差，请以小程序为准</p>
          <div>
            <div class="pt10 pb10 pr10 pl10 w100p borderbox">
              <p class="fs9 fwbold">设计名片</p>
              <div class="mb10 cA3">建议尺寸1080*640px</div>
              <div class="pl10 mb10 d-flex">
                <div class="w70"></div>
                <el-upload accept="image/jpeg,image/png" list-type="picture-card" class="addCardImg"
                  :show-file-list="false" :action="uploadUrl" :on-success="cardBackSuccess"
                  :before-upload="beforeUploadImageDynamicPic">
                  <!-- <img v-if="backImg" :src="backImg" class="avatar dynamic"> -->
                  <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-delete">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                  <div class="nullImg" slot="default">
                    <i class="el-icon-plus"></i>
                    <p>上传名片背景图</p>
                  </div>
                </el-upload>
              </div>
              <el-divider content-position="left">背景库</el-divider>
              <div class="d-flex flex-wrap-y pl15 pr15">
                <div v-for="(url, index) in uploadBackImgList" class="modelBackImg" :key="'u' + index"
                  @click="checkedBack(url)" :class="{ 'on': backImg == url }">
                  <div class="onImg"><i class="el-icon-success"></i></div>
                  <el-image class="mImg" :src="url"></el-image>
                  <i class="el-icon-delete-solid" @click="delCardBack(index)"></i>
                </div>
                <div v-for="(url, index) in backImgList" class="modelBackImg" :key="index" @click="checkedBack(url)"
                  :class="{ 'on': backImg == url }">
                  <div class="onImg"><i class="el-icon-success"></i></div>
                  <el-image class="mImg" :src="url"></el-image>
                </div>
              </div>
            </div>
            <el-collapse class="mr10 ml10" v-model="setCardIndex" @change="showSetCard" :accordion="true">
              <el-collapse-item :name="index" v-for="(item, index) in setCardStyle" :key="index">
                <template slot="title">
                  <el-switch v-model="item.isShow" class="mr20" v-if="item.key != 'info'">
                  </el-switch>
                  <i class="el-icon-delete mr20 corange" @click="delCardInfo(index)" v-if="item.key == 'info'"></i>{{
    item.title }}
                </template>
                <div v-if="item.key == 'logo'">
                  <div class="d-flex pl10 pt10">
                    <div class="w70">
                      展示Logo
                    </div>
                    <div>
                      <div class="addLogo">
                        <img class="el-upload-list__item-thumbnail" :src="item.url" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="d-flex pl10 mt10 styleFun bgf5f6">
                    <div class="mr20">
                      使用圆形外观
                    </div>
                    <div>
                      <el-switch v-model="item.borderRadius" inactive-value="10%" active-value="50%">
                      </el-switch>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="pl10 mb10 pt10 flex-a-c">
                    <div class="w50">
                      内容
                    </div>
                    <el-input type="text" :disabled="item.key !== 'info'" v-model="item.content" class="w300"
                      show-word-limit>
                    </el-input>
                  </div>
                  <el-divider content-position="left">样式设置</el-divider>
                  <div class="pl10 mb10 flex-a-c">
                    <div class="w50">
                      图标
                    </div>
                    <el-upload accept="image/jpeg,image/png" class="cardInfoIcon" list-type="picture-card"
                      :action="uploadUrl" :on-success="cardInfoIconSuccess.bind(null, { 'index': index, 'data': item })"
                      :class="item.iconFont ? 'disable' : ''" :file-list="item.iconFontFile"
                      :before-upload="beforeUploadImageDynamicPic">
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="file" slot-scope="{file}">
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                        <span class="el-upload-list__item-actions">
                          <span class="el-upload-list__item-delete" @click="delCardInfoIcon(index)">
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                  </div>
                  <div class="pl10 mb10 flex-a-c">
                    <div class="w50">
                      字体
                    </div>
                    <el-select v-model="item.fontFamily" class="w250" placeholder="请选择">
                      <el-option v-for="(item, index) in familyData" :key="index" :label="item.title"
                        :value="item.style">
                      </el-option>
                    </el-select>
                    <el-color-picker class="ml10" v-model="item.color" show-alpha :predefine="textColorData">
                    </el-color-picker>
                  </div>
                  <div class="pl10 flex-a-b-c">
                    <div class="flex-a-c">
                      <div class="w50">字号</div>
                      <el-slider v-model="item.size" class="w300" :max="24" :min="12"></el-slider>
                    </div>
                  </div>
                  <div class="flex-c-c pl10 mt10 styleFun bgf5f6">
                    <div>
                      <div class="pr10">
                        <el-radio-group v-model="item.textAlign">
                          <el-radio-button label="flex-start">左对齐</el-radio-button>
                          <el-radio-button label="center">水平居中</el-radio-button>
                          <el-radio-button label="flex-end">右对齐</el-radio-button>
                        </el-radio-group>
                      </div>
                      <div class="mt10 mb10" style="width:100%; height:1px; background-color: #dddddd;"></div>
                      <div>
                        <el-radio-group v-model="item.alignItems">
                          <el-radio-button label="flex-start">上对齐</el-radio-button>
                          <el-radio-button label="center">垂直居中</el-radio-button>
                          <el-radio-button label="flex-end">下对齐</el-radio-button>
                        </el-radio-group>
                      </div>
                    </div>
                    <div style="width:1px; height:40px; background-color: #dddddd;"></div>
                    <div>
                      <div class="pl10">
                        <el-checkbox-button v-model="item.fontWeight" label="bold" true-label="bold"
                          false-label="">加粗</el-checkbox-button>
                      </div>
                      <div class="mt10 mb10" style="width:100%; height:1px; background-color: #dddddd;"></div>
                      <div class="pl10">
                        <el-checkbox-button v-model="item.fontStyle" label="oblique" true-label="oblique"
                          false-label="">倾斜</el-checkbox-button>
                      </div>
                    </div>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="flex-c-c">
            <el-button type="primary" class="mt10 w400" round @click="showAddCardInfoFun">添加名片信息</el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="添加名片信息" :visible.sync="showAddCardInfo" width="400px" :modal-append-to-body="false">
      <div class="yq-tabs">
        <div class="yq-tab flex-c-c" :class="{ 'on': checkType == title }" v-for="(title, index) in addTabData"
          :key="index" @click="checkTypeFun(title)">
          {{ title }}
        </div>
        <el-button class="mr10 ml10 addInfoType flex-c-c" @click="showInfoTypeFun" v-if="!showInfoType">+
          添加类型</el-button>
        <el-input class="infoType mr10 ml10" ref="infoType" v-model="infoType" v-if="showInfoType"
          @blur="addInfoType"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddCardInfo = false">取 消</el-button>
        <el-button type="primary" @click="addCardInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  saveTabMenu,
  queryTabMenu,

  findBusinessCardDetail,
  updateUserDefined,
  updateCardV2,

  getCompany,
  updateCompanyUserDefined,

  getCardStyleModelList,
} from "@/api/wechat";
export default {
  name: "appointmentList",
  data() {
    return {
      phoneHeader: require("@/assets/images/phoneHeader.png"),
      uploadUrl: this.$store.state.uploadingUrl,
      ossUrl: this.$store.state.ossUrl,
      setViewType: "card",
      tabList: [
        {
          text: "名片",
          subheading: "名片",
          pagePath: "pages/index/main",
          iconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/card.png",
          selectedIconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/card_select.png"
        },
        {
          text: "媒体",
          subheading: "媒体",
          pagePath: "pages/media/index",
          iconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/video.png",
          selectedIconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/video_select.png"
        },
        {
          text: "商城",
          subheading: "商城",
          pagePath: "pages/Products/main",
          iconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/prod_gray.png",
          selectedIconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/prod_select.png"
        },
        {
          text: "动态",
          subheading: "动态",
          pagePath: "pages/Dynamic/main",
          iconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/browser.png",
          selectedIconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/browser_select.png"
        },
        {
          text: "官网",
          subheading: "官网",
          pagePath: "pages/WebSite/main",
          iconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/computer.png",
          selectedIconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/computer_select.png"
        },
      ],
      optionList: [
        {
          text: "名片",
          subheading: "名片",
          disabled: true,
          pagePath: "pages/index/main",
          iconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/card.png",
          selectedIconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/card_select.png"
        },
        {
          text: "媒体",
          subheading: "媒体",
          type: "video",
          disabled: true,
          pagePath: "pages/media/index",
          iconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/video.png",
          selectedIconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/video_select.png"
        },
        {
          text: "商城",
          subheading: "商城",
          disabled: true,
          pagePath: "pages/Products/main",
          iconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/prod_gray.png",
          selectedIconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/prod_select.png"
        },
        {
          text: "动态",
          subheading: "动态",
          disabled: true,
          pagePath: "pages/Dynamic/main",
          iconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/browser.png",
          selectedIconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/browser_select.png"
        },
        {
          text: "官网",
          subheading: "官网",
          disabled: true,
          pagePath: "pages/WebSite/main",
          iconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/computer.png",
          selectedIconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/computer_select.png"
        },
        {
          text: "增值服务",
          subheading: "增值服务",
          disabled: false,
          pagePath: "pages/services/main",
          iconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/member.png",
          selectedIconPath: this.$store.state.ossUrl + "/yimai_photos/tabbarIcon/member_select.png"
        },
      ],
      isUpdate: false,
      //设置名片配置
      showAddCardInfo: false,
      addTabData: [
        "名称",
        "手机",
        "地址",
        "座机",
        "邮箱",
        "公司",
      ],
      isDisabledSetCard: false,
      textColorData: [
        "#eee",
        '#ffffff',
        '#ff4500',
        'rgba(237, 179, 98, 1)',
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        '#c7158577',
        "#000000",

      ],
      userInfo: this.$store.state.loginRoot.userInfo,
      backImg: "",
      uploadBackImgList: [],
      backImgList: [],
      logoFile: [],
      setCardStyle: [],//名片数据
      familyData: [
        {
          title: "思源宋体",
          style: "SimSun",
        },
        {
          title: "思源黑体",
          style: "syht",
        },
        {
          title: "楷体",
          style: "KaiTi",
        },
        // {
        //   title: "方正综艺体",
        //   style: "fzzyt",
        // },
        // {
        //   title: "方正风雅宋",
        //   style: "fzfys",
        // },
        // {
        //   title: "方正兰亭黑体",
        //   style: "fzltht",
        // },
        // {
        //   title: "方正兰亭圆体",
        //   style: "fzltyt",
        // },
        // {
        //   title: "方正美黑",
        //   style: "fzmh",
        // },
        // {
        //   title: "方正清刻本悦宋",
        //   style: "fzqkbys",
        // },
        // {
        //   title: "方正宋黑",
        //   style: "fzsh",
        // },
        // {
        //   title: "方正雅士黑",
        //   style: "fzysh",
        // },
      ],
      allTopLeft: [],//全部top的值
      setCardIndex: 0,//选中的信息内容
      isMove: false,
      originX: 0,//鼠标对应框边的距离 对应内容
      originY: 0,//鼠标对应框边的距离 对应内容
      cardTopSize: 0,//名片坐标位置
      cardLeftSize: 0,//名片坐标位置
      cardWidth: 355,//名片大小
      cardHeight: 213,//名片大小
      //添加名称信息类型配置
      getCardLoading: false,//加载状态
      infoType: "",
      showInfoType: false,
      checkType: "",
      //拖拽大小配置
      setSizeType: "",
      openSetSize: false,
      currentsetSizeY: 0,//我点击在缩放点的位置 对应浏览器
      currentsetSizeX: 0,//我点击在缩放点的位置 对应浏览器
      setHeight: 0,//点击时内容的高
      setWidth: 0,//点击时内容的宽
      setSizeTop: 0,//点击时内容的坐标 对应名片
      setSizeLeft: 0,//点击时内容的坐标 对应名片
      //模板配置
      modelList: [],
      modelId: "",
      modelIndex: 0,
      versionData: {
        0: "标准版",
        1: "高级版",
        2: "数字版"
      },
      openSwitchType: false,
      oldSetViewType: "",
      unifiedStyle: false,
      modelImg: "",
      isShowscale: false,
      cardInfo: {},
      modelType: "",
      logoWidth: 50,
      logoHeight: 100
    };
  },
  watch: {
    tabList: {
      handler(val, old) {
        this.optionList.forEach(v => {
          v.disabled = false
          val.forEach((item, index) => {
            if (item.pagePath == v.pagePath) {

              v.disabled = true
            }
          })
        })
      },
      deep: true,
      immediate: true
    }
  },
  filters: {
    filtration(key, tabList) {
      tabList.forEach(item => {
        if (item.text == key) {
          return true
        } else {
          return false
        }
      })
    }
  },
  mounted() {
    this.queryTabList();
    this.getInitData();
  },
  methods: {
    setShowscale(isShowscale) {
      this.isShowscale = isShowscale;
    },
    /**@method 是否开启统一企业模板 */
    unifiedStyleFun() {
      updateCompanyUserDefined({ unifiedStyle: this.unifiedStyle }).then(res => {
        if (res.code === 200) {
          this.$message.success("已开启名片统一");
        } else {
          this.$message.error(res.message);
        }
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    beforeLeave(oldActiveName) {
      this.oldSetViewType = oldActiveName;
      if (oldActiveName != 'nav') {
        if (!this.openSwitchType) {
          return false;
        } else {
          this.openSwitchType = false;
        }
      }
    },
    /**@method 切换编辑名片类型 */
    switchCardType(tab) {
      if (this.oldSetViewType != 'nav') {
        this.$confirm("切换编辑类型后，无法保留当前模板样式的所有编辑操作", "确定切换", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.openSwitchType = true;
          this.setViewType = tab.name;
          if (tab.name === 'card') {
            this.getCardInfo();
          } else if (tab.name === 'comanyCard') {
            this.getCompanyCardInfo();
          }
        })
      }
    },
    /**@method 选择名片模板 */
    checkedModel(row, index) {
      if (this.modelId != row.modelId) {
        this.$confirm("切换模板样式后，无法保留当前模板样式的所有编辑操作", "确定切换", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.modelIndex = index;
          this.modelSetCardInfo(index);
        })
      }
    },
    /**@method 获取默认数据 */
    setCardInfoData() {
      for (let key in this.setCardStyle) {
        let row = this.setCardStyle[key];
        if (row.key === 'logo') {
          row.url = this.cardInfo.logo;
        } else if (row.key === 'company') {
          row.content = this.cardInfo.companyName
        } else if (row.key === 'userName') {
          row.content = this.cardInfo.name;
        } else if (row.key === 'phone') {
          row.content = this.cardInfo.phone
        } else if (row.key === 'address') {
          row.content = this.cardInfo.address
        } else if (row.key === 'position') {
          row.content = this.cardInfo.position
        } else if (row.key === 'email') {
          row.content = this.cardInfo.email
        }
        this.$set(this.setCardStyle, key, this.setCardStyle[key])
      }
    },
    /**@method 获取初始化数据 */
    getInitData() {
      this.getCardLoading = true;
      this.getCardStyleModelList().then(() => {
        this.getCardInfo();
      })
    },
    /**@method 提交个人名片信息 */
    submitCardInfo() {
      let cardInfo = [];
      let userData = {//修改名片的数据
        cardId: this.userInfo.cardId,
      };
      for (let row of this.setCardStyle) {
        let obj = { ...row };
        delete obj.iconFontFile;
        cardInfo.push(obj);
        if (row.key === 'logo' && row.url) {
          userData.logo = row.url;
        } else if (row.key === 'userName' && row.content) {
          userData.name = row.content
        } else if (row.key === 'phone' && row.content) {
          userData.phone = row.content
        } else if (row.key === 'address' && row.content) {
          userData.address = row.content
        } else if (row.key === 'email' && row.content) {
          userData.email = row.content
        }
      }
      let params = {//修改名片样式的数据
        cardId: this.userInfo.cardId,
        userDefined: JSON.stringify({
          modelId: this.modelId,
          modelImg: this.modelImg,
          modelType: this.modelType,
          width: this.logoWidth,
          height: this.logoHeight,
          cardBackgroundImg: this.backImg,
          cardInfo: cardInfo,
        })
      };
      Promise.all([
        new Promise(open => {//提交修改名片信息
          updateCardV2(userData).then(res => {
            if (res.code === 200) {
              open();
            }
          })
        }),
        new Promise(open => {//提交修改名片样式
          updateUserDefined(params).then(res => {
            if (res.code === 200) {
              open();
            } else {
              this.$message.error(err.message);
            }
          }).catch(err => {
            if (err.code === 201) {
              this.$message.error(err.message);
            }
          })
        })
      ]).then(res => {
        this.$message.success("编辑成功");
        this.getCardInfo();
      })
    },
    /**@method 提交企业名片信息 */
    submitCompanyCardInfo() {
      let cardInfo = [];
      for (let row of this.setCardStyle) {
        let obj = { ...row };
        delete obj.iconFontFile;
        cardInfo.push(obj);
        if (row.key !== 'info') {
          row.content = "";
        }
      }
      let params = {//修改名片样式数据
        cardId: this.userInfo.cardId,
        userDefined: JSON.stringify({
          modelId: this.modelId,
          modelImg: this.modelImg,
          modelType: this.modelType,
          width: this.logoWidth,
          height: this.logoHeight,
          cardBackgroundImg: this.backImg,
          cardInfo: cardInfo,
        })
      };
      //提交名片样式
      updateCompanyUserDefined(params).then(res => {
        if (res.code === 200) {
          this.$message.success("编辑成功");
          this.getCompanyCardInfo();
        } else {
          this.$message.error(err.message);
        }
      }).catch(err => {
        if (err.code === 201) {
          this.$message.error(err.message);
        }
      })
    },
    /**@method 获取企业名片信息 */
    getCompanyCardInfo() {
      this.getCardLoading = true;
      getCompany({}).then(res => {
        this.getCardLoading = false;
        if (res.data) {
          let cardInfo = res.data
          if (cardInfo.userDefined) {
            let userDefined = JSON.parse(cardInfo.userDefined);
            this.modelId = userDefined.modelId;
            this.backImg = userDefined.cardBackgroundImg;
            this.setCardStyle = JSON.parse(JSON.stringify(userDefined.cardInfo));
            for (let row of this.setCardStyle) {
              if (row.key === 'logo' && row.url) {
                this.logoFile = [{ url: row.url }]
              } else if (row.key === 'company') {
                row.content = cardInfo.companyName;
              }
            }
            for (let index in this.modelList) {
              let item = this.modelList[index];
              if (item.modelId == userDefined.modelId) {
                this.modelIndex = index;
                this.backImgList = item.backgroundList;
                break;
              }
            }
            if (userDefined.cardBackgroundImg && this.backImgList.indexOf(userDefined.cardBackgroundImg) == -1 && this.uploadBackImgList.indexOf(userDefined.cardBackgroundImg) == -1) {
              this.uploadBackImgList.unshift(userDefined.cardBackgroundImg);
            }
          } else {
            let modelData = this.modelList[0]
            this.modelIndex = 0;
            this.backImgList = this.modelList[0].backgroundList;
            this.modelId = modelData.modelId;
            this.backImg = modelData.styleData.cardBackgroundImg;
            this.setCardStyle = JSON.parse(JSON.stringify(modelData.styleData.cardInfo));
          }
          this.$nextTick(() => {
            this.setCardInfoStyle();
          })
        } else {
          this.$message.error(res.message);
          this.isDisabledSetCard = true;
          this.setViewType = "nav";
        }
      }).catch(err => {
        this.isDisabledSetCard = true;
        this.getCardLoading = false;
        this.setViewType = "nav";
        if (err.code === 201) {
          this.$message.error(err.message);
        }
      })
    },
    /**@method 获取名片信息 */
    getCardInfo() {
      this.getCardLoading = true;
      findBusinessCardDetail({ cardId: this.userInfo.cardId }).then(res => {
        this.getCardLoading = false;
        if (res.data) {
          let cardInfo = res.data;
          this.cardInfo = res.data;
          this.unifiedStyle = cardInfo.useUnified;
          if (cardInfo.userDefined) {
            /**
             * 获取名片编辑信息
             *  设置初始化模板id
             *  设置名片样式
             *  设置名片背景图
             */
            let userDefined = JSON.parse(cardInfo.userDefined);
            this.backImg = userDefined.cardBackgroundImg;
            this.modelId = userDefined.modelId;
            this.setCardStyle = JSON.parse(JSON.stringify(userDefined.cardInfo));
            this.modelType = userDefined.modelType;
            this.logoWidth = userDefined.width;
            this.logoHeight = userDefined.height;
            for (let index in this.modelList) {
              let item = this.modelList[index];
              if (item.modelId == userDefined.modelId) {
                this.modelIndex = index;
                this.backImgList = item.backgroundList;
                break;
              }
            }
            if (userDefined.cardBackgroundImg && this.backImgList.indexOf(userDefined.cardBackgroundImg) == -1 && this.uploadBackImgList.indexOf(userDefined.cardBackgroundImg) == -1) {
              this.uploadBackImgList.unshift(userDefined.cardBackgroundImg);
            }

          } else {
            /**
             * 用户没有名片样式时
             *  默认使用第一个模板数据
             *  设置初始化模板id
             *  设置名片样式
             *  设置名片背景图
             */
            this.modelSetCardInfo();
          }
          this.setCardInfoData();
          this.$nextTick(() => {
            this.setCardInfoStyle();
          })
        } else {
          this.$message.error(res.message);
          this.isDisabledSetCard = true;
          this.setViewType = "nav";
        }
      }).catch(err => {
       //console.log(err);
        this.isDisabledSetCard = true;
        this.getCardLoading = false;
        this.setViewType = "nav";
        if (err.code === 201) {
          this.$message.error(err.message);
        }
      })
    },
    /**@method 切换模板 */
    modelSetCardInfo(i = 0) {
      /**
       * 切换模板赋值名片样式
       * 重新赋值名片内容
       * 调节名片内容位置
       */
      let modelData = this.modelList[i];
      this.modelId = modelData.modelId;
      let modelStyle = JSON.parse(modelData.content);
      this.modelType = modelStyle.modelType;
      this.setCardStyle = modelStyle.cardInfo;
      this.backImg = modelStyle.cardBackgroundImg;
      this.logoWidth = modelData.width;
      this.logoHeight = modelData.height;
      if (modelData.backgroundList.length > 0) {
        this.backImgList = modelData.backgroundList;
      }
      this.setCardInfoData();
      this.$nextTick(() => {
        this.setCardInfoStyle();
      })
    },
    /**@method 获取模板列表 */
    getCardStyleModelList() {
      return getCardStyleModelList({ cardId: this.userInfo.cardId }).then(res => {
        if (res.data) {
          for (let row of res.data) {
            this.modelList.push({
              ...row,
              backgroundList: row.background.split(","),
              styleData: JSON.parse(row.content),
            })
          }
          this.modelId = this.modelList[0].modelId;
          this.modelImg = this.modelList[0].photo;
          this.backImgList = []
          for (let url of this.modelList[0].background.split(",")) {
            this.backImgList.push(url)
          }
        }
      })
    },
    /**@method 设置名片信息初始位置 */
    setCardInfoStyle() {
      let $cardInfo = document.getElementsByClassName("cardInfo");
      for (let key in this.setCardStyle) {
        let setCardStyle = this.setCardStyle[key]
        // $cardInfo[key].style.top = setCardStyle.top + "px";
        // $cardInfo[key].style.left = setCardStyle.left + "px";
        if (setCardStyle.key != 'logo' || this.modelType === 'allBackgroundImg') {
          $cardInfo[key].style.transform = `translate(${setCardStyle.left}px,${setCardStyle.top}px)`;
          $cardInfo[key].style.width = setCardStyle.width + "px";
          $cardInfo[key].style.height = setCardStyle.height + "px";
        } else {
          $cardInfo[key].style.width = this.logoWidth + "%";
          $cardInfo[key].style.height = this.logoHeight + "%";
        }
        if (setCardStyle.iconFont) {
          setCardStyle.iconFontFile = [{ url: setCardStyle.iconFont }]
        } else {
          setCardStyle.iconFontFile = [];
        }
      }
      //在名片内容块，添加事件
      this.moveFun();
    },
    checkTypeFun(title) {
      this.checkType = title;
    },
    /**@method 删除名片内容 */
    delCardInfo(index) {
      this.setCardStyle.splice(index, 1);
    },
    /**@method 添加名片内容 */
    addCardInfo() {
      if (this.checkType) {
        this.setCardStyle.push({
          content: "请编辑内容",
          title: this.checkType,
          key: "info",
          top: 0,
          left: 0,
          height: 30,
          width: 200,
          size: 20,
          color: "#fff",
          textAlign: "flex-start",
          alignItems: "center",
          fontFamily: "syht",
          fontStyle: "",
          fontWeight: "",
          iconFont: "",
          isShow: true,
        })
        this.showAddCardInfo = false;
      } else {
        this.$message.info("请选择要添加的标签");
      }

    },
    showInfoTypeFun() {
      this.infoType = "";
      this.showInfoType = true;
      this.$nextTick(() => {
        this.$refs.infoType.$refs.input.focus();
      })
    },
    /**@method 添加名片内容类型 */
    addInfoType() {
      this.showInfoType = false;
      if (this.infoType && this.addTabData.indexOf(this.infoType) == -1) {
        this.addTabData.push(this.infoType);
      }
    },

    showAddCardInfoFun() {
      this.showAddCardInfo = true;
    },
    /**@method 点击编辑名片信息展开手风琴*/
    showSetCard(val) {
      if (val) {
        this.setCardIndex = val;
      }
    },
    /**@method 选中背景图 */
    checkedBack(url) {
      this.backImg = url;
    },
    /**@method 删除名片背景 */
    delCardBack(i) {
      this.uploadBackImgList.splice(i, 1);
      if (this.uploadBackImgList.length > 0) {
        this.backImg = this.uploadBackImgList[0];
      } else {
        this.backImg = this.backImgList[0];
      }
    },
    /**@method 上传名片背景 */
    cardBackSuccess(res) {
      if (res.data) {
        let url = this.ossUrl + res.data;
        this.backImg = url;
        if (this.uploadBackImgList.indexOf(url) == -1 && this.backImgList.indexOf(url) == -1) {
          this.uploadBackImgList.unshift(url);
        }
      }
    },
    /**@method logo图上传 */
    cardInfoIconSuccess(obj, res) {
      if (res.data) {
        this.$set(this.setCardStyle[obj.index], "iconFont", this.ossUrl + res.data);
      }
    },
    /**@method logo删除 */
    delCardInfoIcon(index) {
      this.setCardStyle[index].iconFont = "";
      this.setCardStyle[index].iconFontFile = [];
    },
    logoSuccess(res) {
      if (res.data) {
        this.$set(this.setCardStyle[0], "url", this.ossUrl + res.data)
      }
    },
    delLogoImg() {
      this.$set(this.setCardStyle[0], "url", "")
      this.logoFile = [];
    },
    /**@method 名片信息拖拽结束 */
    moveEnd() {
      this.isMove = false;
      this.openSetSize = false;
      let $lineTop = document.getElementsByClassName("lineTop")[0];
      let $lineRight = document.getElementsByClassName("lineRight")[0];
      let $lineBottom = document.getElementsByClassName("lineBottom")[0];
      let $lineLeft = document.getElementsByClassName("lineLeft")[0];
      let $lineCenterX = document.getElementsByClassName("lineCenterX")[0];
      let $lineCenterY = document.getElementsByClassName("lineCenterY")[0];
      $lineTop.style.display = 'none';
      $lineRight.style.display = 'none';
      $lineBottom.style.display = 'none';
      $lineLeft.style.display = 'none';
      $lineCenterX.style.display = 'none';
      $lineCenterY.style.display = 'none';
    },
    /**@method 名片信息拖拽开始 */
    moveStart(e, index) {
      let allTopLeft = [];
      for (let key in this.setCardStyle) {
        if (key != index) {
          if (this.setCardStyle[key].isShow != false || !("isShow" in this.setCardStyle[key])) {
            allTopLeft.push({
              ...this.setCardStyle[key]
            });
          }
        }
      }
      this.allTopLeft = allTopLeft;//获取全部距离
      this.setCardIndex = index;
      this.isMove = true;
      this.originX = e.offsetX;//获取鼠标对应框边的距离
      this.originY = e.offsetY;//获取鼠标对应框边的距离
      let domCard = document.getElementsByClassName('setCard')[0];
      let domCardRect = domCard.getBoundingClientRect();
      this.cardTopSize = domCardRect.top;//获取拖动区域到浏览器窗口顶部的距离
      this.cardLeftSize = domCardRect.left;//获取拖动区到浏览器窗口左边的距离
      // this.cardWidth = domCard.offsetWidth;//获取拖动区域宽
      // this.cardHeight = domCard.offsetHeight;//获取拖动区高
    },
    /**@method 拖动大小 */
    moveSizeStart(e, type) {
      this.setSizeType = type;//边的类型
      this.openSetSize = true;//开启修改大小
      this.currentsetSizeY = e.clientY;//获取我点击的位置
      this.currentsetSizeX = e.clientX;//获取我点击的位置
      let setCardStyle = this.setCardStyle[this.setCardIndex]
      this.setHeight = setCardStyle.height;//获取当前选中信息的高度
      this.setWidth = setCardStyle.width;//获取当前选中信息的宽度
      this.setSizeTop = setCardStyle.top;//获取当前选中信息的上距离
      this.setSizeLeft = setCardStyle.left;//获取当前选中信息的左距离
    },
    /**@method 名片信息拖拽 */
    moveFun() {
      let that = this;
      document.getElementsByClassName("appointmentList-view")[0].onmousemove = function (e) {
        let $cardInfo = document.getElementsByClassName("cardInfo")[that.setCardIndex];
        //缩放
        if (that.openSetSize) {
          //距离计算
          let setCardStyle = that.setCardStyle[that.setCardIndex]
          let reverseMoveDistanceY = that.currentsetSizeY - e.clientY;
          let reverseMoveDistanceX = that.currentsetSizeX - e.clientX;
          let moveDistanceX = e.clientX - that.currentsetSizeX;//获取缩放距离
          let moveDistanceY = e.clientY - that.currentsetSizeY;//获取缩放距离
          //限制范围计算
          let maxTop = that.setSizeTop + that.setHeight;
          let maxLeft = that.setSizeLeft + that.setWidth;
          if (setCardStyle.key === 'logo') {
            let logoHeight = 0;
            let logoWidth = 0;
            let logoTop = that.setSizeTop + moveDistanceX;//获取移动的距离，吧距离加到宽度高上
            let logoLeft = that.setSizeLeft + moveDistanceX;
            let logoMoveTop = logoTop >= maxTop ? maxTop : logoTop;
            let logoMoveLeft = logoLeft >= maxLeft ? maxLeft : logoLeft;
            if (that.setSizeType === 'leftTop' || that.setSizeType === 'leftCenter') {
              setCardStyle.left = logoMoveLeft < 0 ? 0 : logoMoveLeft;
              logoHeight = setCardStyle.height;
              logoWidth = setCardStyle.width;
            } else if (that.setSizeType === 'leftBottom') {
              logoHeight = that.setHeight + reverseMoveDistanceX;
              logoWidth = that.setWidth + reverseMoveDistanceX;
              setCardStyle.left = logoMoveLeft;//减去推动距离
            } else if (that.setSizeType === 'rightTop') {
              logoHeight = that.setHeight + moveDistanceX;//获取移动的距离，吧距离加到宽度高上
              logoWidth = that.setWidth + moveDistanceX;
              logoTop = that.setSizeTop + reverseMoveDistanceX;
              logoMoveTop = logoTop >= maxTop ? maxTop : logoTop;
              setCardStyle.top = logoMoveTop;//减去推动距离
            } else if (that.setSizeType === 'rightBottom' || that.setSizeType === 'rightCenter') {
              logoHeight = that.setHeight + moveDistanceX;//获取移动的距离，吧距离加到宽度高上
              logoWidth = that.setWidth + moveDistanceX;
            } else if (that.setSizeType === 'bottomCenter') {
              logoHeight = that.setHeight + moveDistanceY;
              logoWidth = that.setWidth + moveDistanceY;
            } else if (that.setSizeType === 'topCenter') {
              logoHeight = that.setHeight + reverseMoveDistanceY;
              logoWidth = that.setWidth + reverseMoveDistanceY;
              logoTop = that.setSizeTop + moveDistanceY;
              logoTop = logoTop >= maxTop ? maxTop : logoTop;
              logoLeft = that.setSizeLeft + moveDistanceY;
              logoLeft = logoLeft >= maxLeft ? maxLeft : logoLeft;
              setCardStyle.top = logoTop;//减去推动距离
              setCardStyle.left = logoLeft;//减去推动距离
            }
            setCardStyle.height = logoHeight < 0 ? 0 : logoHeight;
            setCardStyle.width = logoWidth < 0 ? 0 : logoWidth;
            $cardInfo.style.height = logoHeight < 0 ? 0 : logoHeight + 'px';
            $cardInfo.style.width = logoWidth < 0 ? 0 : logoWidth + 'px';
            $cardInfo.style.transform = `translate(${setCardStyle.left}px,${setCardStyle.top}px)`;



          } else {
            //高宽计算
            let setHeight = that.setHeight + moveDistanceY;
            let setWidth = that.setWidth + moveDistanceX;
            let setInfoHeight = setHeight < 20 ? 20 : setHeight;
            let setInfoWidth = setWidth < 18 ? 18 : setWidth;
            let setReverseHeight = that.setHeight + reverseMoveDistanceY;
            let setReverseWidth = that.setWidth + reverseMoveDistanceX;
            let setReverseInfoHeight = setReverseHeight < 20 ? 20 : setReverseHeight;
            let setReverseInfoWidth = setReverseWidth < 18 ? 18 : setReverseWidth;
            if (that.setSizeType === 'rightBottom') {
              setCardStyle.height = setInfoHeight;
              setCardStyle.width = setInfoWidth;
            } else if (that.setSizeType === 'rightCenter') {
              setCardStyle.width = setInfoWidth;
            } else if (that.setSizeType === 'rightTop') {
              setCardStyle.height = setReverseInfoHeight;
              setCardStyle.width = setInfoWidth;
              let moveTop = that.setSizeTop + moveDistanceY;
              setCardStyle.top = moveTop > (maxTop - 20) ? maxTop - 20 : moveTop;
            } else if (that.setSizeType === 'leftBottom') {
              setCardStyle.height = setInfoHeight;
              setCardStyle.width = setReverseInfoWidth;
              let moveLeft = that.setSizeLeft + moveDistanceX;
              setCardStyle.left = moveLeft > (maxLeft - 18) ? maxLeft - 18 : moveLeft;
            } else if (that.setSizeType === 'leftCenter') {
              setCardStyle.width = setReverseInfoWidth;
              let moveLeft = that.setSizeLeft + moveDistanceX;
              setCardStyle.left = moveLeft > (maxLeft - 18) ? maxLeft - 18 : moveLeft;
            } else if (that.setSizeType === 'leftTop') {
              setCardStyle.height = setReverseInfoHeight;
              setCardStyle.width = setReverseInfoWidth;
              let moveLeft = that.setSizeLeft + moveDistanceX;
              let moveTop = that.setSizeTop + moveDistanceY;
              setCardStyle.left = moveLeft > (maxLeft - 18) ? maxLeft - 18 : moveLeft;
              setCardStyle.top = moveTop > (maxTop - 20) ? maxTop - 20 : moveTop;
            } else if (that.setSizeType === 'topCenter') {
              setCardStyle.height = setReverseInfoHeight;
              let moveTop = that.setSizeTop + moveDistanceY;
              setCardStyle.top = moveTop > (maxTop - 20) ? maxTop - 20 : moveTop;
            } else if (that.setSizeType === 'bottomCenter') {
              setCardStyle.height = setInfoHeight;
            }
            $cardInfo.style.height = setCardStyle.height + 'px';
            $cardInfo.style.width = setCardStyle.width + 'px';
            $cardInfo.style.transform = `translate(${setCardStyle.left}px,${setCardStyle.top}px)`;
          }
        }
        //名片内容移动
        //polygon(left top, 100% 50%, 50% 100%, 0% 50%);
        if (that.isMove) {
          //获取当前选中的内容
          let cardTextStyle = that.setCardStyle[that.setCardIndex];
          //获取鼠标坐标
          let y = (e.clientY - that.cardTopSize) - that.originY;
          let x = (e.clientX - that.cardLeftSize) - that.originX;
          let top = Number(y) > 0 ? Number(y) : 0;
          let left = Number(x) > 0 ? Number(x) : 0;
          top = (top + (cardTextStyle.height)) > that.cardHeight ? (that.cardHeight - cardTextStyle.height) : top;
          left = (left + (cardTextStyle.width)) > that.cardWidth ? (that.cardWidth - cardTextStyle.width) : left;
          let topMatchData = {};
          let bottomMatchData = {};
          let centerXMatchData = {};
          let leftMatchData = {};
          let centerYMatchData = {};
          let rightMatchData = {};
          //匹配坐标
          for (let row of that.allTopLeft) {
            if (Math.abs(top - (row.top)) < 10 || Math.abs(top - (row.top + row.height)) < 10) {//匹配最近一条的坐标数据
              topMatchData = row;
            }
            if (Math.abs((top + cardTextStyle.height) - row.top) < 10 || Math.abs((top + cardTextStyle.height) - (row.top + row.height)) < 10) {//匹配最近一条的坐标数据
              bottomMatchData = row;
            }
            if (Math.abs((top + (cardTextStyle.height / 2)) - (row.top + (row.height / 2))) < 10) {//匹配最近一条的坐标数据
              centerXMatchData = row;
            }
            if (Math.abs(left - (row.left)) < 10 || Math.abs(left - (row.left + row.width)) < 10) {//匹配最近一条的坐标数据
              leftMatchData = row;
            }
            if (Math.abs((left + (cardTextStyle.width / 2)) - (row.left + (row.width / 2))) < 10) {//匹配最近一条的坐标数据
              centerYMatchData = row;
            }
            if (Math.abs((left + cardTextStyle.width) - row.left) < 10 || Math.abs((left + cardTextStyle.width) - (row.left + row.width)) < 10) {//匹配最近一条的坐标数据
              rightMatchData = row;
            }
          }
          //匹配到的对齐数据
          //上对齐线匹配到数据
          let matchToTop = topMatchData.top;
          let matchToLeft = topMatchData.left;
          let matchToWidth = topMatchData.width;
          let matchToHeight = topMatchData.height;
          //中对齐线匹配到的数据
          let matchToCenterTop = centerXMatchData.top + (centerXMatchData.height / 2);
          let matchToCenterLeft = centerXMatchData.left;
          let matchToCenterWidth = centerXMatchData.width;
          let matchToCenterHeight = centerXMatchData.height;
          //下对齐线匹配到的数据
          let matchToBottomTop = bottomMatchData.top;
          let matchToBottomLeft = bottomMatchData.left;
          let matchToBottomWidth = bottomMatchData.width;
          let matchToBottomHeight = bottomMatchData.height;
          //左对齐线匹配到数据
          let matchToLeftTop = leftMatchData.top;
          let matchToLeftLeft = leftMatchData.left;
          let matchToLeftWidth = leftMatchData.width;
          let matchToLeftHeight = leftMatchData.height;
          //中对齐线匹配到的数据
          let matchToCenterYTop = centerYMatchData.top;
          let matchToCenterYLeft = centerYMatchData.left + (centerYMatchData.width / 2);
          let matchToCenterYWidth = centerYMatchData.width;
          let matchToCenterYHeight = centerYMatchData.height;
          //右对齐线匹配到的数据
          let matchToRightTop = rightMatchData.top;
          let matchToRightLeft = rightMatchData.left;
          let matchToRightWidth = rightMatchData.width;
          let matchToRightHeight = rightMatchData.height;

          let $lineTop = document.getElementsByClassName("lineTop")[0];
          let $lineRight = document.getElementsByClassName("lineRight")[0];
          let $lineBottom = document.getElementsByClassName("lineBottom")[0];
          let $lineLeft = document.getElementsByClassName("lineLeft")[0];
          let $lineCenterX = document.getElementsByClassName("lineCenterX")[0];
          let $lineCenterY = document.getElementsByClassName("lineCenterY")[0];
          //计算线条高宽
          //下对齐线长
          let lineHeihgt = ((top + cardTextStyle.height) - matchToTop);
          lineHeihgt = lineHeihgt < matchToHeight ? matchToHeight : lineHeihgt;
          if (top < matchToTop) {
            lineHeihgt = Math.abs(top - matchToTop) + lineHeihgt;
          }
          //上对齐
          if (Math.abs(matchToTop - top) < 10 || Math.abs((matchToTop + matchToHeight) - top) < 10) {
            //上对齐线长
            let lineTopWidth = Math.abs((left + cardTextStyle.width) - matchToLeft);
            if (left < matchToLeft) {
              lineTopWidth = Math.abs(left - (matchToLeft + matchToWidth));
            }
            let fixedTopLineWidth = cardTextStyle.width < matchToWidth ? matchToWidth : cardTextStyle.width;
            lineTopWidth = lineTopWidth < fixedTopLineWidth ? fixedTopLineWidth : lineTopWidth;
            let lineTopLeft = left < matchToLeft ? left : matchToLeft;
            if (left < matchToLeft) {
              lineTopWidth = Math.abs(left - lineTopLeft) + lineTopWidth;
            }
            //上对齐线
            if (Math.abs(matchToTop - top) < 10) {
              top = matchToTop;
            }
            if (Math.abs((matchToTop + matchToHeight) - top) < 10) {
              top = (matchToTop + matchToHeight);
            }
            $lineTop.style.top = top + 'px';
            $lineTop.style.left = lineTopLeft + 'px';
            $lineTop.style.width = lineTopWidth + 'px';
            if (matchToTop == top || (matchToTop + matchToHeight) == top) {
              $lineTop.style.display = 'block';
            }
          } else {
            $lineTop.style.display = 'none';
          }
          //中对齐
          if (Math.abs(matchToCenterTop - (top + (cardTextStyle.height / 2))) < 10) {
            //中对齐线长
            let lineXCenterWidth = Math.abs((left + cardTextStyle.width) - matchToCenterLeft);
            if (left < matchToCenterLeft) {
              lineXCenterWidth = Math.abs(left - (matchToCenterLeft + matchToCenterWidth));
            }
            let fixedXCenterLineWidth = cardTextStyle.width < matchToCenterWidth ? matchToCenterWidth : cardTextStyle.width;
            lineXCenterWidth = lineXCenterWidth < fixedXCenterLineWidth ? fixedXCenterLineWidth : lineXCenterWidth;
            let lineXCenterLeft = left < matchToCenterLeft ? left : matchToCenterLeft;
            if (left < matchToCenterLeft) {
              lineXCenterWidth = Math.abs(left - lineXCenterLeft) + lineXCenterWidth;
            }
            //中对齐线
            if (Math.abs(matchToCenterTop - (top + (cardTextStyle.height / 2))) < 10) {
              top = matchToCenterTop - (cardTextStyle.height / 2) > 0 ? matchToCenterTop - (cardTextStyle.height / 2) : 0;
            }
            $lineCenterX.style.top = (top + (cardTextStyle.height / 2)) + 'px';
            $lineCenterX.style.left = lineXCenterLeft + 'px';
            $lineCenterX.style.width = lineXCenterWidth + 'px';
            if (matchToCenterTop == (top + (cardTextStyle.height / 2)) || (matchToCenterTop + matchToCenterHeight) == (top + (cardTextStyle.height / 2))) {
              $lineCenterX.style.display = 'block';
            }
          } else {
            $lineCenterX.style.display = 'none';
          }
          //下对齐
          if (Math.abs(matchToBottomTop - (top + cardTextStyle.height)) < 10 || Math.abs((matchToBottomTop + matchToBottomHeight) - (top + cardTextStyle.height)) < 10) {
            //下对齐线长
            let lineBottomWidth = Math.abs((left + cardTextStyle.width) - matchToBottomLeft);
            if (left < matchToBottomLeft) {
              lineBottomWidth = Math.abs(left - (matchToBottomLeft + matchToBottomWidth));
            }
            let fixedBottomLineWidth = cardTextStyle.width < matchToBottomWidth ? matchToBottomWidth : cardTextStyle.width;
            lineBottomWidth = lineBottomWidth < fixedBottomLineWidth ? fixedBottomLineWidth : lineBottomWidth;
            let lineBottomLeft = left < matchToBottomLeft ? left : matchToBottomLeft;
            if (left < matchToBottomLeft) {
              lineBottomWidth = Math.abs(left - lineBottomLeft) + lineBottomWidth;
            }
            //下对齐线
            if (Math.abs(matchToBottomTop - (top + cardTextStyle.height)) < 10) {
              top = matchToBottomTop - cardTextStyle.height > 0 ? matchToBottomTop - cardTextStyle.height : 0;
            }
            if (Math.abs((matchToBottomTop + matchToBottomHeight) - (top + cardTextStyle.height)) < 10) {
              top = (matchToBottomTop + matchToBottomHeight) - cardTextStyle.height > 0 ? (matchToBottomTop + matchToBottomHeight) - cardTextStyle.height : 0;
            }
            $lineBottom.style.top = (top + cardTextStyle.height) + 'px';
            $lineBottom.style.left = lineBottomLeft + 'px';
            $lineBottom.style.width = lineBottomWidth + 'px';
            if (matchToBottomTop == (top + cardTextStyle.height) || (matchToBottomTop + matchToBottomHeight) == (top + cardTextStyle.height)) {
              $lineBottom.style.display = 'block';
            }
          } else {
            $lineBottom.style.display = 'none';
          }
          // //左对齐线
          if (Math.abs(matchToLeftLeft - left) < 10 || Math.abs((matchToLeftLeft + matchToLeftWidth) - left) < 10) {
            //左对齐线长
            let lineLeftHeight = Math.abs((top + cardTextStyle.width) - matchToLeftTop);
            if (top < matchToLeftTop) {
              lineLeftHeight = Math.abs(top - (matchToLeftTop + matchToLeftHeight));
            }
            let fixedLineLeftHiehgt = cardTextStyle.height < matchToLeftHeight ? matchToLeftHeight : cardTextStyle.height;
            lineLeftHeight = lineLeftHeight < fixedLineLeftHiehgt ? fixedLineLeftHiehgt : lineLeftHeight;
            let lineLeftTop = top < matchToLeftTop ? top : matchToLeftTop;
            if (top < matchToLeftTop) {
              lineLeftHeight = Math.abs(top - lineLeftTop) + lineLeftHeight;
            }
            //左对齐线
            if (Math.abs(matchToLeftLeft - left) < 10) {
              left = matchToLeftLeft;
            }
            if (Math.abs((matchToLeftLeft + matchToLeftWidth) - left) < 10) {
              left = (matchToLeftLeft + matchToLeftWidth);
            }
            $lineLeft.style.top = lineLeftTop + 'px';
            $lineLeft.style.left = left + 'px';
            $lineLeft.style.height = lineLeftHeight + 'px';
            if (matchToLeftLeft == left || (matchToLeftLeft + matchToLeftWidth) == left) {
              $lineLeft.style.display = 'block';
            }
          } else {
            $lineLeft.style.display = 'none';
          }
          //中对齐
          if (Math.abs(matchToCenterYLeft - (left + (cardTextStyle.width / 2))) < 10) {
            //中对齐线长
            let lineYCenterHeihgt = Math.abs((top + cardTextStyle.height) - matchToCenterYTop);
            if (top < matchToCenterYTop) {
              lineYCenterHeihgt = Math.abs(top - (matchToCenterYTop + matchToCenterYHeight));
            }
            let fixedYCenterLineHeight = cardTextStyle.height < matchToCenterYHeight ? matchToCenterYHeight : cardTextStyle.height;
            lineYCenterHeihgt = lineYCenterHeihgt < fixedYCenterLineHeight ? fixedYCenterLineHeight : lineYCenterHeihgt;
            let lineYCenterTop = top < matchToCenterYTop ? top : matchToCenterYTop;
            if (top < matchToCenterYTop) {
              lineYCenterHeihgt = Math.abs(top - lineYCenterTop) + lineYCenterHeihgt;
            }
            //中对齐线
            if (Math.abs(matchToCenterYLeft - (left + (cardTextStyle.width / 2))) < 10) {
              left = Math.abs(matchToCenterYLeft - (cardTextStyle.width / 2));
            }
            $lineCenterY.style.top = lineYCenterTop + 'px';
            $lineCenterY.style.left = (left + (cardTextStyle.width / 2)) + 'px';
            $lineCenterY.style.height = lineYCenterHeihgt + 'px';
            if (matchToCenterYLeft == (left + (cardTextStyle.width / 2)) || (matchToCenterYLeft + matchToCenterYWidth) == (left + (cardTextStyle.width / 2))) {
              $lineCenterY.style.display = 'block';
            }
          } else {
            $lineCenterY.style.display = 'none';
          }
          //右对齐线
          if (Math.abs(matchToRightLeft - (left + cardTextStyle.width)) < 10 || Math.abs((matchToRightLeft + matchToRightWidth) - (left + cardTextStyle.width)) < 10) {
            //右对齐线长
            let lineRightHeight = Math.abs((top + cardTextStyle.height) - matchToRightTop);
            if (top < matchToRightTop) {
              lineRightHeight = Math.abs(top - (matchToRightTop + matchToRightHeight));
            }
            let fixedLineYCenterHeight = cardTextStyle.height < matchToRightHeight ? matchToRightHeight : cardTextStyle.height;
            lineRightHeight = lineRightHeight < fixedLineYCenterHeight ? fixedLineYCenterHeight : lineRightHeight;
            let lineRightTop = top < matchToRightTop ? top : matchToRightTop;
            if (top < matchToRightTop) {
              lineRightHeight = Math.abs(top - lineRightTop) + lineRightHeight;
            }
            //右对齐线
            if (Math.abs(matchToRightLeft - (left + cardTextStyle.width)) < 10) {
              left = Math.abs(matchToRightLeft - cardTextStyle.width);
            }
            if (Math.abs((matchToRightLeft + matchToRightWidth) - (left + cardTextStyle.width)) < 10) {
              left = Math.abs((matchToRightLeft + matchToRightWidth) - cardTextStyle.width);
            }
            $lineRight.style.top = lineRightTop + 'px';
            $lineRight.style.left = (left + cardTextStyle.width) + 'px';
            $lineRight.style.height = lineRightHeight + 'px';
            if (matchToRightLeft == (left + cardTextStyle.width) || (matchToRightLeft + matchToRightWidth) == (left + cardTextStyle.width)) {
              $lineRight.style.display = 'block';
            }
          } else {
            $lineRight.style.display = 'none';
          }
          cardTextStyle.top = top;
          cardTextStyle.left = left;
          // $cardInfo.style.top = top + 'px';
          // $cardInfo.style.left = left + 'px';
          $cardInfo.style.transform = `translate(${left}px,${top}px)`;

        }
      }
    },
    //请求表格数据
    queryTabList() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
      };
      queryTabMenu(data)
        .then((res) => {
          //-console.log(res)
          if (res.data && res.data.note) {
            this.tabList = JSON.parse(res.data.note)
            //-console.log(this.tabList)
          }
        })
        .catch((err) => {
          this.loading = false;
          this.tabList = this.tabList;
        });
    },
    beforeUploadImageDynamicPic(file) {
      var _this = this;
      var isLt10M = file.size / 1024 / 1024 < 10;
      if (['image/jpeg', 'image/png'].indexOf(file.type) == -1) {
        _this.$message.error('请上传正确的图片格式');
        return false;
      }
      if (!isLt10M) {
        _this.$message.error('上传图片大小不能超过10MB哦!');
        return false;
      }
    },

    //动态图上传成功
    dynamicPicSuccess(obj, res, file) {
      var index = obj.index;
      this.$set(this.tabList[index], 'iconPath', this.ossUrl + '/' + res.data)
    },
    select_tap(key, index) {
      let than = this
      //-console.log(key)
      this.optionList.forEach(v => {
        if (v.text == key) {
          than.tabList[index].pagePath = v.pagePath
          than.tabList[index].iconPath = v.iconPath
          than.tabList[index].subheading = v.subheading
          than.tabList[index].selectedIconPath = v.selectedIconPath
          than.tabList[index].type = v.type
        }
      })
    },

    //动态图上传成功
    dynamicPicSuccessA(obj, res, file) {
      var index = obj.index;
      this.$set(this.tabList[index], 'selectedIconPath', this.ossUrl + '/' + res.data)
    },
    deleteTab(item, index) {
      if (index == 0) {
        return false
      }
      let than = this
      if (than.tabList.length == 2) {
        return than.$message.error('小程序最少需要两个导航栏！')
      }
      than.tabList.forEach((v, k) => {
        if (v.text == item.text) {
          than.tabList.splice(k, 1)
          //-console.log(than.tabList,'than.tabList')
        }
      })
    },
    addTab() {

      if (this.tabList.length >= 5) {
        return this.$message.error('小程序最多五个导航栏！')
      }
      if (this.tabList.length == 0) {
        this.tabList.push({
          text: "名片",
          iconPath: 'static/tabs/card.png',
          selectedIconPath: 'static/tabs/card_select.png',
          subheading: '名片',
          pagePath: "pages/index/main",
        })
      } else {
        this.tabList.push({
          text: "",
          iconPath: '',
          subheading: '',
          selectedIconPath: ''
        })
      }
    },
    //保存菜单
    saveTab() {
      let content = []
      for (var k in this.tabList) {
        let v = this.tabList[k]
        if (!v.iconPath || !v.pagePath || !v.selectedIconPath || !v.text) {
          return this.$message.error('导航栏信息不完整，请确认！')
        }
        if (k == 0) {
          v.text = "名片";
        }
        switch (v.pagePath) {
          case 'pages/index/main':
            content.push({
              text: v.text,
              subheading: '名片',
              iconPath: 'static/tabs/card.png',
              selectedIconPath: 'static/tabs/card_select.png',
              pagePath: "pages/index/main",
            })
            break;
          case 'pages/media/index':
            content.push({
              text: v.text,
              subheading: '媒体',
              iconPath: 'static/tabs/video.png',
              selectedIconPath: 'static/tabs/video_select.png',
              pagePath: "pages/media/index",
            })
            break;
          case 'pages/Products/main':
            content.push({
              text: v.text,
              subheading: '商城',
              iconPath: 'static/tabs/prod_gray.png',
              selectedIconPath: 'static/tabs/prod_select.png',
              pagePath: "pages/Products/main",
            })
            break;
          case 'pages/Dynamic/main':
            content.push({
              text: v.text,
              subheading: '动态',
              iconPath: 'static/tabs/browser.png',
              selectedIconPath: 'static/tabs/browser_select.png',
              pagePath: "pages/Dynamic/main",
            })
            break;
          case 'pages/WebSite/main':
            content.push({
              text: v.text,
              subheading: '官网',
              iconPath: 'static/tabs/computer.png',
              selectedIconPath: 'static/tabs/computer_select.png',
              pagePath: "pages/WebSite/main",
            })
            break;
          case 'pages/services/main':
            content.push({
              text: v.text,
              subheading: '增值服务',
              iconPath: 'static/tabs/member.png',
              selectedIconPath: 'static/tabs/member_select.png',
              pagePath: "pages/services/main",
            })
            break;
          default:
            break;
        }
      }
      let data = {
        note: JSON.stringify(this.tabList),
        content: JSON.stringify(content)
      }
      saveTabMenu(data)
        .then(res => {
          this.$message.success(res.message)
        })
    }
  }
};
</script>

<style lang="scss">
.setLogo img {
  -webkit-user-drag: none;
}

.addInfoType {
  height: 34px;
  width: 100px;
}

.infoType {
  width: 100px;
  height: 34px;

  .el-input__inner {
    height: 34px;
    line-height: 34px;
  }
}

.addCardImg.disable .el-upload,
.addLogo.disable .el-upload,
.cardInfoIcon.disable .el-upload {
  display: none !important;

}
</style>
<style lang="scss" scoped>
.yq-tabs {
  display: flex;
  flex-wrap: wrap;

  .yq-tab {
    height: 34px;
    font-size: 16px;
    width: 100px;
    border-radius: 3px;
    border: 1px solid #dcdfe6;
    cursor: pointer;
    margin: 0 10px;
    margin-bottom: 20px;
  }

  .yq-tab.on {
    border: 1px solid #51CDCB;
    color: #51CDCB;
  }
}

.appointmentList-view {
  display: flex;
  background: #ffffff;
  padding: 0;
  height: 780px;
}

.modelList {
  width: 200px;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
  height: 780px;
  padding: 0 10px;
  overflow-y: scroll
}

.modelImg,
.modelBackImg {
  width: 160px;
  height: 92px;
  border-radius: 8px;
  margin: 10px;
  border: 2px solid #e7e7e7;
  cursor: pointer;
  overflow: hidden;
  position: relative;

  .mImg {
    height: 100%;
    width: 100%;
  }

  .onImg {
    background-color: rgba(82, 205, 204, .2);
    height: 100%;
    width: 100%;
    position: absolute;
    display: none;
    z-index: 2;
    justify-content: center;
    align-items: center;
  }
}

.modelImg.on,
.modelBackImg.on {
  border: 2px solid #51CDCB;

  .onImg {
    display: flex;

    i {
      font-size: 25px;
      color: #51CDCB;
    }
  }
}

.modelImg {
  .version {
    position: absolute;
    left: 0px;
    top: 5px;
    font-size: 12px;
    z-index: 3;
    color: #fff;

    >span {
      padding: 2px 0;
      padding-left: 5px;
      padding-right: 5px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.1);
    }

    .zc {
      background: #C0CBCC;
    }

    .bzb {
      background: #51CDCB;
    }

    .gjb {
      background: #f7b365;
    }

    .szb {
      background: #1da9ff;
    }
  }
}

.modelBackImg {
  width: 95px;
  height: 55px;

  .el-icon-delete-solid {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 20px;
    color: #F56C6C;
    z-index: 3;
  }
}

.setView::-webkit-scrollbar,
.app-view::-webkit-scrollbar,
.app-view2::-webkit-scrollbar,
.web-view::-webkit-scrollbar,
.modelList::-webkit-scrollbar {
  display: none;
}

.setView {
  width: 530px;
  height: 780px;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;

  .styleFun {
    padding: 10px 0;
  }

  .showIcon {
    border: 1px solid #DCDFE6;
    height: 32px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    cursor: pointer;
  }
}

.app-view {
  width: calc(100% - 750px);
  overflow-y: scroll;
  height: 780px;
  position: relative;
}

.app-view2 {
  width: calc(100% - 550px);
  overflow-y: scroll;
  height: 780px;
  position: relative;
}

.web-view {
  height: 730px;
  width: 375px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  .setCardBottom {
    height: 250px;
    width: 100%;
    position: absolute;
    top: 0;
    background: #fff;
  }

  .setCard {
    position: relative;
    height: 213px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    background-size: 100% 100%;
    background-position: center center;
    margin-top: 17px;
    margin-left: 10px;
    width: 355px;
    border-radius: 5px;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .cardBackimg {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 2;
      background-size: 100% 100%;
      border-radius: 5px;
    }

    .hide {
      display: none;
    }

    .cardInfo {
      position: absolute;
      z-index: 3;

      .setLogo {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 8px;
        text-align: center;

        >div {
          width: 100%;
          height: 100%;
        }
      }

      .infoIconFont {
        // margin-top: 2px;
        width: 18px;
        height: 18px;
      }


    }

    .cardInfo.leftHalfBackgroundImg {
      transform: translate(0, 0) !important;
      z-index: 1 !important;
      // height: 100% !important;
      bottom: 0 !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      // border-top-left-radius: 5px !important;
      // border-bottom-left-radius: 5px !important;

      .logoImg {
        background-image: url('https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/photo/20230710/215e8705f1d17c4cdc1f112aa29e786b.png');
        background-size: 100% 100% !important;
        border-radius: 0 !important;
        // border-top-left-radius: 5px !important;
        // border-bottom-left-radius: 5px !important;

      }

    }

    .cardInfo.rightHalfBackgroundImg {
      transform: translate(0, 0) !important;
      z-index: 1 !important;
      // height: 100% !important;
      right: 0 !important;
      bottom: 0 !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      // border-top-right-radius: 5px !important;
      // border-bottom-right-radius: 5px !important;

      .logoImg {
        background-image: url('https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/one-stand/photo/20230710/215e8705f1d17c4cdc1f112aa29e786b.png');
        background-size: 100% 100% !important;
        border-radius: 0 !important;
        // border-top-right-radius: 5px !important;
        // border-bottom-right-radius: 5px !important;
      }
    }

    .cardInfo.on {
      box-shadow: 0 0 0 2px #1da9ff;
      cursor: move;
      z-index: 999;
    }

    .setText {
      padding: 0 2px;
      overflow: hidden;
      height: 100%;
      width: 100%;
    }

    .cardInfo.on .ltd,
    .cardInfo.on .lcd,
    .cardInfo.on .lbd,
    .cardInfo.on .tcd,
    .cardInfo.on .rtd,
    .cardInfo.on .rcd,
    .cardInfo.on .rbd,
    .cardInfo.on .bcd {
      display: block;
    }

    .ltd,
    .lcd,
    .lbd,
    .tcd,
    .rtd,
    .rcd,
    .rbd,
    .bcd {
      display: none;
      position: absolute;
      background: #fff;
      border: 1px solid #333;
      box-shadow: 0 0 2px #bbb;
      width: 8px;
      height: 8px;
    }

    .ltd {
      cursor: nw-resize;
      top: -4px;
      left: -4px;
    }

    .lcd {
      cursor: w-resize;
      top: 50%;
      left: -4px;
      margin-top: -4px;
    }

    .lbd {
      cursor: sw-resize;
      bottom: -4px;
      left: -4px;
    }

    .tcd {
      cursor: n-resize;
      top: -4px;
      left: 50%;
      margin-left: -4px;
    }

    .rtd {
      cursor: ne-resize;
      right: -4px;
      top: -4px;
    }

    .rcd {
      cursor: e-resize;
      right: -4px;
      top: 50%;
      margin-top: -4px;
    }

    .rbd {
      cursor: se-resize;
      right: -4px;
      bottom: -4px;
    }

    .bcd {
      cursor: s-resize;
      bottom: -4px;
      left: 50%;
      margin-left: -4px;
    }
  }

  .line {
    background: #ff4b31;
    display: none;
    position: absolute;
    z-index: 9999;
  }

  .lineTop,
  .lineBottom,
  .lineCenterX {
    height: 1px;
    width: 800px;
  }

  .lineRight,
  .lineLeft,
  .lineCenterY {
    width: 1px;
    height: 500px;
  }
}

.phoneClass {
  // padding: 50px 35px 0px 35px;
  border: 7px solid #f0f0f0;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top: none;
  // background-image: url('../../../assets/images/iphonex.png');
  height: 820px;
  width: 390px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
}

.phoneHeader {
  width: 390px;
  height: 70px;
  display: block;
}

.phoneClass img {
  width: 100%;
}

/deep/.addCardImg .el-upload--picture-card {
  width: 300px;
  height: 176px;
  margin-right: 20px;
  line-height: 35px;

  .nullImg {
    font-size: 25px;
    margin-top: 55px;
  }
}

/deep/ .addCardImg .el-upload-list__item,
.addCardImg .el-upload-list__item-thumbnail {
  width: 300px;
  height: 176px;
  line-height: 176px;
}

/deep/.addLogo .el-upload--picture-card {
  width: 70px;
  height: 70px;
  line-height: 80px;
}

/deep/ .addLogo .el-upload-list__item,
.addLogo .el-upload-list__item-thumbnail {
  width: 70px;
  height: 70px;
  line-height: 70px;
}

/deep/.cardInfoIcon .el-upload--picture-card {
  width: 32px;
  height: 32px;
  line-height: 30px;
}

/deep/.cardInfoIcon .el-upload--picture-card .el-icon-plus {
  font-size: 12px;
}

/deep/ .cardInfoIcon .el-upload-list__item,
.cardInfoIcon .el-upload-list__item-thumbnail {
  width: 32px;
  height: 32px;
  line-height: 30px;
}



/deep/.addTabIcon .el-upload--text {
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 20px;
}

/deep/ .addTabIcon .el-upload-list__item {
  width: 50px;
  height: 50px;
  line-height: 50px;
}

.bradius4 {
  border-radius: 4px;
}

.textc {
  text-textAlign: center
}

.bced {
  border: 1px solid #CED4DA
}

.ohidden {
  overflow: hidden
}

.icon-plus {
  position: relative;
  cursor: pointer;
  margin-right: 20px;
}

.icon-plus:before,
.icon-plus:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(206, 212, 218);

}

.icon-plus:before {
  height: 60%;
  width: 1px;
}

.icon-plus:after {
  height: 1px;
  width: 60%;
}

.opacity0 {
  z-index: 1;
  opacity: 0;
}

.position-relative {
  position: relative;
}

.goods-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.avatar {
  width: 100%;
  height: 100%;
}

.submitCard {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
}
</style>
